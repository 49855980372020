import { render, staticRenderFns } from "./TypographyThemeSection.vue?vue&type=template&id=628d3e1f&scoped=true"
import script from "./TypographyThemeSection.vue?vue&type=script&lang=js"
export * from "./TypographyThemeSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css-loader@6.7.3_webpack@5.9_6s55l47c55t3vezsjmfggkbvbe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628d3e1f",
  null
  
)

export default component.exports