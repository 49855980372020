import { AssessmentType, Feature } from "@YenzaCT/sdk";

export const ReportRouterNames = {
  [Feature.WorkerTypeReport]: "hollandCodeReportPage",
  [Feature.EntrepreneurReport]: "entrepreneurReportPage",
  [Feature.PersonalityTraitsReport]: "personalityReportPage",
  [Feature.AreasOfDevelopmentReport]: "areasOfDevelopmentReportPage",
  [Feature.LearningStylesReport]: "learningStylesReportPage",
  [Feature.EmotionalIntelligenceReport]: "emotionalIntelligenceReportPage",
  [Feature.CognitiveAbilityReport]: "cognitiveAbilityReportPage",
  [Feature.ValueScalesReport]: "valueScalesReportPage",
};

export const ASSESSMENT_LABELS = {
  [AssessmentType.EntrepreneurialIntent]: "Entrepreneur",
  [AssessmentType.HollandCode]: "Holland Code",
  [AssessmentType.Big5]: "Big 5",
  [AssessmentType.UnderstandingEmotion]: "Understanding Emotions",
  [AssessmentType.PerceivingEmotion]: "Perceiving Emotions",
  [AssessmentType.ManagingEmotion]: "Managing Emotions",
  [AssessmentType.RockPaperScissors]: "Rock Paper Scissors",
  [AssessmentType.Maze]: "Maze",
  [AssessmentType.Interests]: "Interests",
  [AssessmentType.Ipip]: "IPIP",
  [AssessmentType.LearningStyles]: "Learning Styles",
};

export const EXPLORER_LABELS = {
  [Feature.SkillsExplorer]: "Skills",
  [Feature.CareerExplorer]: "Careers",
  [Feature.SubjectChoiceExplorer]: "Subject Choice",
  [Feature.TertiaryInstitutionExplorer]: "Tertiary",
  [Feature.FundingExplorer]: "Funding",
  [Feature.ResourcesExplorer]: "Resources",
};

export const RESEARCH_ASSESSMENTS = {
  [AssessmentType.HollandCode]: [AssessmentType.Interests, AssessmentType.EntrepreneurialIntent],
  [AssessmentType.Big5]: [AssessmentType.Ipip],
  [AssessmentType.ManagingEmotion]: [AssessmentType.StemB, AssessmentType.SteuB],
};

export const WORKER_TYPES = {
  "A": "artistic",
  "C": "conventional",
  "E": "enterprising",
  "I": "investigative",
  "R": "realistic",
  "S": "social"
};

export default {
  ASSESSMENT_LABELS,
  EXPLORER_LABELS,
  WORKER_TYPES,
};
